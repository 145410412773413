import React from 'react'
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Script } from "gatsby"
import { Link } from "gatsby"


import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'



const GbiBridged = () => {
  const { heroImage, logoImage, contentImage1, contentImage2 } = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logoImage: file(relativePath: { eq: "logo-inverted.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        contentImage1: file(relativePath: { eq: "wrap.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        contentImage2: file(relativePath: { eq: "toning.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      
    `
  )
  const image = getImage(heroImage)
  const image2 = getImage(logoImage)
  const image3 = getImage(contentImage1)
  const image4 = getImage(contentImage2)


  // Use like this:
  const bgImage = convertToBgImage(image)


  



  return (
    
    <Layout>
 
    

      <BackgroundImage
      Tag="section"
      id="hero"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext>

      <div className="logo">
      <GatsbyImage image={image2} alt={"image2"}/>
      </div>
     
      </BackgroundImage>
 
    <main>
      <div id="content">
      <section className="row text-center headings">
      <h1 className='h1-heading'>Solfilm &amp; Foliering</h1>
      <h2 className='h2-heading'>För fordon och fastigheter</h2>
      </section>
     <section className="row box">

      <div className="col col-12 col-md-6">
        <div className="inner-content"> 
        <h2>VARFÖR SOLFILM?</h2>
       <span>
      Tonade rutor ger ökad komfort, större säkerhet och betydligt snyggare utseende. Filmen tar bort 99% av UV-strålarna vilket skyddar bilens interiör mot blekning och missfärgning.</span> 

      <Link className="link" to="/solfilm">Läs mer</Link>
       </div>

      </div>
      <div className="col col-12 col-md-6">
        <GatsbyImage image={image4} alt={"image4"} className="content-image" width="1000"/>

      </div>

      </section>
      <section className="row box">

      <div className="col col-12 col-md-6">
        <GatsbyImage image={image3} alt={"image3"} className="content-image" width="1000"/>

      </div>
      <div className="col col-12 col-md-6">
      <div className="inner-content"> 
        <h2>VARFÖR FOLIERING?</h2>
       <span>
       Foliering ger ett bra skydd mot repor och slag eller annan åverkan. Vi folierar allt ifrån bilar och husbilar till skåpsluckor och elsparkcyklar.</span> 
       <Link className="link" to="/foliering">Läs mer</Link>
       </div>

      </div>
      </section>
     <section className="row box">

      <div className="col col-12 text-center">
      <div className="inner-content"> 
      <h2 className="text-center mb-3 wide">

     Lång garanti
      </h2>

      <span>När du anlitar oss kan du räkna med att jobbet blir fint utfört. Du kan kalla oss perfektionister som aldrig ger sig förrän det blir bra. Vi sätter kundens önskemål i fokus och vill alltid att du ska vara nöjd. Därför kan vi erbjuda nöjd kund-garanti samt <b>10 års garanti</b> på solfilm och <b>3 års garanti</b> på foliering.</span>
      </div>
      </div>

</section>

     </div>
     </main>
   

  </Layout>


  )
}

export default GbiBridged


